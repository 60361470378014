
/* eslint-disable */
import { defineComponent, PropType, ref, onMounted } from "@cloudfun/core";
import { Sorting, SortOrder, Condition } from "@cloudfun/core";
import { ContentLoader } from "vue-content-loader";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export interface Album {
  Id: number;
  StartDate?: string;
  EndDate?: string;
  CoverUri: string;
  Title: string;
  PicturesCount?: number;
  Owner?: { Id: number, Name: string };
}

export interface AlbumOptions {
  /** 非同步設定 */
  promises?: {
    /** 非同步查詢 */
    query?: (params: {
      page: number;
      pageSize: number;
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<{ data: Album[]; pageCount: number; }>;
  };
}

export default defineComponent({
  name: "CfAlbumList",
  components: {
    ContentLoader,
    VPagination
  },
  props: {
    /** 可提供的承諾 */
    promises: {
      type: Object as PropType<{
        query?: (params: {
          page: number;
          pageSize: number;
          keyword?: string;
          sortings?: Sorting[];
          condition?: Condition;
        }) => Promise<{ data: Album[]; pageCount: number; }>;
        // save?: (params: {
        //   insertRows?: any[];
        //   updateRows?: any[];
        //   deleteRows?: any[];
        // }) => Promise<void>;
      }>,
    },
  },
  emits: ["itemClick"],
  setup(props, { emit }) {
    // const selectedRow = ref(-1);
    const data = ref<Album[]>([]);
    const sortings = ref<Sorting[]>([]);
    const loading = ref(false);
    const paginationConfig = {
      currentPage: 1,
      pages: 1
    };
    const loadData = () => {
      if (props.promises?.query) {
        loading.value = true;
        props.promises.query({ page: paginationConfig.currentPage, pageSize: 20, condition: new Condition(), sortings: sortings.value }).then(res => {
          data.value = res.data;
          paginationConfig.pages = res.pageCount;
          
        }).finally(() => {
          loading.value = false;
        });
      }
    }

    onMounted(() => {
      loadData();
    })
    const current: any = ref(null)
    const onItemClick = (type: "body" | "footer", item: Album, index: number) => {
      current.value = index;
      emit("itemClick", { type, item, index });
    }
    const updateHandler = (e:any)=>{
      paginationConfig.currentPage = e;
      window.scrollTo(0,0);
      loadData();
    }

    return {
      data,
      defaultImage: require("@/assets/images/placeholder/placeholder.svg"),
      loading,
      loadData,
      onItemClick,
      current,
      paginationConfig,
      updateHandler
    };
  },
  methods: {
    reload() {
      this.loadData();
    }
  }
});
