import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "3xl:container mx-auto px-24" }
const _hoisted_2 = { class: "mt-6 grid grid-cols-5 gap-x-6 gap-y-10" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "mt-2 text-base font-normal text-secondary truncate overflow-ellipsis overflow-hidden hover:cursor-pointer transition duration-300 ease-in-out hover:text-primary" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "mt-2 text-base font-normal text-tertiary truncate overflow-ellipsis overflow-hidden hover:cursor-pointer transition duration-300 ease-in-out hover:text-primary" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `album-${item.Id}-${index}`
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("a", {
              class: _normalizeClass(["square-box group", {'border border-success': _ctx.current === index }]),
              onClick: _withModifiers(($event: any) => (_ctx.onItemClick('body', item, index)), ["prevent"])
            }, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ContentLoader, {
                    key: 0,
                    primaryColor: "#1B1B1B",
                    secondaryColor: "#212121",
                    class: "square-content"
                  }))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: item.CoverUri || _ctx.defaultImage,
                    alt: item.Title,
                    class: "square-content hover:cursor-pointer object-center object-cover"
                  }, null, 8, _hoisted_5))
            ], 10, _hoisted_4),
            _createElementVNode("div", {
              class: "relative mt-4",
              onClick: _withModifiers(($event: any) => (_ctx.onItemClick('footer', item, index)), ["prevent"])
            }, [
              _createElementVNode("h3", _hoisted_7, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_ContentLoader, {
                      key: 0,
                      primaryColor: "#1B1B1B",
                      secondaryColor: "#212121",
                      width: "32",
                      height: "16px"
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.Title), 1))
              ]),
              _createElementVNode("p", _hoisted_9, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_ContentLoader, {
                      key: 0,
                      primaryColor: "#1B1B1B",
                      secondaryColor: "#212121",
                      width: "64",
                      height: "16px"
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.PicturesCount) + " images", 1))
              ])
            ], 8, _hoisted_6)
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_v_pagination, {
        modelValue: _ctx.paginationConfig.currentPage,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paginationConfig.currentPage) = $event)),
          _ctx.updateHandler
        ],
        pages: _ctx.paginationConfig.pages,
        "range-size": 1,
        "active-color": "#1B1B1B"
      }, null, 8, ["modelValue", "pages", "onUpdate:modelValue"])
    ])
  ]))
}