

import Cloudfun, { defineComponent, ref, reactive, watch, onMounted, Sorting, SortOrder } from "@cloudfun/core";
import { onUnmounted } from "vue";
// import CfTabsBar from "@/components/tabs-bar/Main.vue";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import CfToolBar from "@/components/tool-bar/Main.vue";
import CfAlbumList, { Album, AlbumOptions } from "@/components/album/Main.vue";
import RenameModal from "@/components/modal/rename/Main.vue";
import ConfirmRemoveModal from "@/components/modal/confirm-remove/Main.vue";
import SharingGroupModal from "@/components/modal/sharing-group/Main.vue";
import NewAlbumModal from "@/components/modal/new-album/Main.vue";

export default defineComponent({
  components: {
    // CfTabsBar,
    CfToolBar,
    CfTabs,
    CfSearchBar,
    CfAlbumList,
    RenameModal,
    ConfirmRemoveModal,
    SharingGroupModal,
    NewAlbumModal
  },
  setup() {
    const model = Cloudfun.current?.model;
    const router = Cloudfun.current?.policy?.router;
    const permissions: any = Cloudfun.current?.user.Permissions;
    const album = ref();
    const selectedItem = ref<Album | null>(null);
    const keyword = ref("");
    const tabs: any = ref([])

    const albumOptions: AlbumOptions = {
      promises: {
        query: (params) => {
          params.sortings = [new Sorting('CreatedTime', SortOrder.Descending)];
          params.keyword = keyword.value;
          return model!.dispatch("album/query", params)
        }
      }
    }

    const onAlbumClick = ({ type, item }: { type: string, item: Album }) => {
      if (type === "footer") {
        router?.push('/album/' + item.Id);
        return;
      }
      selectedItem.value = item;
    }

    const onSearch = (value: string) => {
      keyword.value = value;
      album.value.reload();
    }

    const newAlbumModal = reactive({
      visible: false,
      show() {
        newAlbumModal.visible = true;
      },
      async submit(item: Album) {
        try {
          await model?.dispatch("album/insert", item);
          newAlbumModal.visible = false;
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const renameModal = reactive({
      visible: false,
      row: {} as Album,
      show() {
        renameModal.visible = true;
        renameModal.row = selectedItem.value!;
      },
      async submit(item: Album) {
        try {
          const temp = await model?.dispatch("album/update", item)
          selectedItem.value = temp;
          renameModal.visible = false;
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const shareModal = reactive({
      visible: false,
      row: {} as Album,
      show() {
        shareModal.visible = true;
        shareModal.row = selectedItem.value!;
      }
    })

    const removeModal = reactive({
      visible: false,
      row: {} as Album,
      show() {
        removeModal.visible = true;
        removeModal.row = selectedItem.value!;
      },
      async submit(item: Album) {
        try {
          await model?.dispatch("album/delete", item.Id);
          selectedItem.value = null;
          removeModal.visible = false;
          album.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    watch(() => model?.state["album"]["reloadUI"], value => {
      if (!value) return;
      album.value.reload();
      model!.state["album"]["reloadUI"] = false;
    })

    let refreshDate = NaN;
    const handler = (event: any) => {
      const file = JSON.parse(event);
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();
      if (!file.params && refreshDate !== today) {
        refreshDate = today;
        album.value.reload();
      }
    }

    onMounted(() => {
      Cloudfun.subscript('uploader:success', handler)
      tabs.value = [
        { name: "PROJECT", href: "/project", current: false, permission: permissions.includes('Project') },
        { name: "ALBUM", href: "/album", current: true, permission: permissions.includes('Album')},
        { name: "MODEL", href: "/model", current: false, permission: permissions.includes('Model')}
      ].filter((tab: any) => {
        return tab.permission === true;
      });
    });
    onUnmounted(() => Cloudfun.unsubscript('uploader:success', handler));

    return {
      album,
      keyword,
      albumOptions,
      selectedItem,
      onAlbumClick,
      onSearch,
      newAlbumModal,
      renameModal,
      shareModal,
      removeModal,
      tabs,
      toolbar: [
        {
          tooltipMsg: "Sharing group",
          icon: "icon-user",
        },
        {
          tooltipMsg: "Delete",
          icon: "icon-delete",
        },
        {
          tooltipMsg: "Rename",
          icon: "icon-edit",
        },
      ],
    };
  },
  methods: {},
});
